<template>
  <v-card
    class="rounded-lg"
    >
    <v-card-text
      class="pa-6 rounded-lg"
      >
      <div
        v-if="fair"
        >
        <div
          v-if="currentUser.selectedProfile.profilableType == 'Store'"
          >
          <div
            class="text-center"
            >
            <v-icon
              size="50"
              color="primary"
              >
              mdi-string-lights
            </v-icon>

            <div
              class="text-h5 font-weight-bold black--text"
              >
              {{ fair.storeIds.includes(currentUser.selectedProfile.profilableId) ? '¡Ya formas parte!' : '¡Únete a la feria!' }}
            </div>

            <div
              class="my-3 py-1 px-4 rounded-lg mx-auto"
              style="border: 1px solid #2196f3; width: fit-content; cursor: pointer"
              @click="redirect"
              >
              <v-icon color="info" class="mr-1">mdi-open-in-new</v-icon>
              <span class="info--text font-weight-bold text-overline">Ver feria</span>
            </div>

            <div
              class="mb-6"
              style="position: relative"
              >
              <v-avatar
                v-if="fair.image && fair.image.url"
                color="primary"
                size="130"
                >
                <v-img
                  :src="url + fair.image.url"
                  ></v-img>
              </v-avatar>

              <v-avatar
                v-else
                color="secondary"
                size="130"
                >
                <v-icon
                  color="white"
                  size="60"
                  >
                  mdi-string-lights
                </v-icon>
              </v-avatar>
            </div>

            <div
              class="text-h6 text-center mt-2 black--text font-weight-bold"
              >
              {{ fair.name }}
            </div>

            <div
              class="body-1 black--text"
              >
              {{ (new Date(fair.startsAt)).toLocaleDateString() }} al {{ (new Date(fair.endsAt)).toLocaleDateString() }}
            </div>

            <div
              class="body-1 black--text mt-2"
              >
              <v-icon color="primary" class="mt-n1">mdi-account</v-icon> {{ fair.owner }}
            </div>

            <div
              class="body-1 text-center mt-2 black--text"
              >
              {{ fair.description }}
            </div>

            <div
              class="body-1 text-center mt-2 white--text font-weight-medium px-3 py-2 primary mx-auto rounded-lg"
              style="width: fit-content"
              >
              Comisión del {{ fair.commission }}%
            </div>

            <v-btn
              block
              color="primary"
              @click="joinFair"
              class="mt-6 rounded-lg"
              large
              v-if="!fair.storeIds.includes(currentUser.selectedProfile.profilableId)"
              >
              ¡Unirme!
            </v-btn>

            <v-btn
              block
              large
              color="error"
              @click="leaveFair"
              class="mt-6 rounded-lg"
              depressed
              outlined
              v-else
              >
              Desinscribirme
            </v-btn>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { Fair } from '@/graphql/queries/fairs/fairs'
import { JoinFair, LeaveFair } from '@/graphql/mutations/fairs/fair_stores'

export default {
  data: () => ({
    fair: null
  }),

  props: {
    id: {
      required: true,
      type: String | Number
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'url'])
  },

  created () {
    this.fetchFair()
  },

  watch: {
    id () {
      this.fetchFair()
    }
  },

  methods: {
    fetchFair () {
      this.$apollo.query({
        query: Fair,
        variables: {
          id: this.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.fair = res.data.fair
        this.loading = false
      })
    },

    joinFair () {
      this.$apollo.mutate({
        mutation: JoinFair,
        variables: {
          input: {
            id: this.id
          }
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.success = true
        this.fetchFair()
      })
    },

    leaveFair () {
      this.$apollo.mutate({
        mutation: LeaveFair,
        variables: {
          input: {
            id: this.id
          }
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.success = true
        this.fetchFair()
      })
    },

    redirect () {
      const uri = `${process.env.VUE_APP_BASE_URL}feria/${this.fair.slug}`

      window.open(uri, '_blank')
    }
  }
}
</script>
